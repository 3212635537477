<template>
  <div class="LoginCard">
    <span class="helpText" v-show="userName">電話號碼</span>
    <div class="input">
      <van-field
        v-model.trim="userName"
        v-emoji
        type="tel"
        maxlength="10"
        placeholder="電話號碼"
        @input="tel"
        @keyup="bankCardKeyup"
      />
    </div>
    <span class="helpText2" v-show="code">短訊驗證碼</span>
    <div class="input">
      <van-field
        v-model="code"
        v-emoji
        type="digit"
        placeholder="短訊驗證碼"
        maxlength="4"
      >
        <template #button>
          <van-button
            size="small"
            round
            color="#B59470"
            v-show="sentCode"
            :disabled="disabled"
            @click="sendCode"
            >發送驗證碼</van-button
          >

          <van-button size="small" round v-show="!sentCode" color="#AF8958"
            >{{ auth_time }}秒後重新獲取</van-button
          >
        </template>
      </van-field>
    </div>
    <van-button
      class="clickButton"
      :disabled="code.length !== 4"
      round
      block
      @click="login"
      >開始投票</van-button
    >
  </div>
</template>

<script>
import { Toast } from 'vant'
import { reqSendCode, reqVerifCode } from '@/api/api'
import { getisweixing } from '@/utils/auth'
export default {
  name: 'SMSLog',
  data() {
    return {
      userName: '',
      code: '',
      sentCode: true,
      auth_time: 60 /*  计数器 */,
      disabled: true // 按钮禁用状态
    }
  },
  methods: {
    sendCode() {
      let number = this.userName
      number = number.replace(/\s+/g, '')
      let params = {}
      if (getisweixing()) {
        params = {
          phone: number,
          wxid: this.$store.state.wxid || localStorage.getItem('wxid')
        }
      } else {
        params = {
          phone: number
        }
      }
      let auth_timetimer = setInterval(() => {
        this.auth_time--
        if (this.auth_time <= 0) {
          this.sentCode = true
          this.auth_time = 60
          clearInterval(auth_timetimer)
        }
      }, 1000)
      this.sentCode = false
      // 獲取驗證碼
      reqSendCode(params)
        .then((result) => {
          // console.log(result)
          // 倒計時
          // let auth_timetimer = setInterval(() => {
          //   this.auth_time--
          //   if (this.auth_time <= 0) {
          //     this.sentCode = true
          //     this.auth_time = 60
          //     clearInterval(auth_timetimer)
          //   }
          // }, 1000)
          // this.sentCode = false
          // console.log(this.sentCode)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    login() {
      if (this.code === '' || this.code.length !== 4) {
        Toast.fail('請輸入正確的驗證碼')
      } else {
        let number = this.userName
        number = number.replace(/\s+/g, '')
        // =====測試數據，要改==================
        const params = {
          code: this.code,
          phone: number
        }

        reqVerifCode(params).then((res) => {
          // console.log(res)
          if (res.code === 200) {
            // console.log(res.token)
            Toast.success('登录成功')
            this.$store.dispatch('login', res.token)
            localStorage.setItem('token', res.token)
            this.$router.push('/home')
          } else {
            Toast.fail(res.msg)
          }
        })
      }
    },
    tel(e) {
      // console.log(e)
      let number = e
      number = number.replace(/\s+/g, '')
      // console.log(number)
      // const reg = /^[6]([8|6])\d{6}$/
      const reg = /^[6]([0-9])\d{6}$/
      if (reg.test(number)) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    bankCardKeyup() {
      this.userName = this.userName.replace(/[^\d]/g, '')
      const value = this.userName.replace(/[^0-9]*/g, '')
      if (value.length > 4 && value.length <= 8) {
        this.userName = value.slice(0, 4) + '  ' + value.slice(4, value.length)
      }
    }
  }
}
</script>

<style scoped lang="less">
@import 'SMSLog.less';
</style>
