
<template>
  <div id="container">
    <div class="bg" style="background: #fff;">
      <van-image
        style="display: none;"
        width="100vw"
        fit="contain"
        :src="require('@/assets/img/ifood-award-logo.jpg')"
      />
      <van-image
        width="100vw"
        fit="contain"
        :src="require('@/assets/img/bg_compress_20240906001.jpg')"
      />
    </div>
    <div style="background-color: #fff;">
      <div class="bg-log-box">
        <van-image
          width="100vw"
          fit="contain"
          :src="require('@/assets/img/bottom-logo_20240902.jpg')"
        />
      </div>
    </div>
    <div class="hengfu-box" @click="hengfuFun" v-if="1==2">
      <van-image
        width="100vw"
        fit="contain"
        :src="require('@/assets/img/banner_AD_compress.png')"
      />
    </div>
    <search @goSearch="goSearch"></search>
    <collapseOption
      :canteenList="categoryList"
      :active="active"
      :cancelCheckRadio="cancelCheckRadio"
      @updateHome="updateHome"
      @isSelect="isSelect"
      @goLoginTips="goLoginTips"
      ref="collapse"
    ></collapseOption>
    <van-image
      class="vanImg"
      width="100vw"
      fit="contain"
      :src="require('@/assets/img/background.png')"
    />
    <goldLine></goldLine>
    <div class="submit-divbox">
      <div class="submitBtn" @click="submit">
      <div class="img"><img src="../../assets/img/2.png" /></div>
      <div>提交</div>
    </div>
    </div>
    <tipsDialog
      :show="isShow"
      :msg="msg"
      :btn1="btnMsg"
      :isBtn1="isBtn1"
      :url="tipsUrl"
      @show="show"
    ></tipsDialog>

    <div class="totop" v-show="toTopShow" @click="toTop()">
      <van-icon name="arrow-up" class="top-icon"/>
      <span class="top-span">TOP</span>
    </div>
  </div>
</template>

<script>
import { getisweixing } from '@/utils/auth'
import { reqCategory, reqCategoryToken, wxTips, timeConfig } from '@/api/api'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
import goldLine from '@/components/goldLine/goldLine.vue'
import search from '@/components/search/search.vue'
import collapseOption from '@/components/collapse/collapse.vue'
import tipsDialog from '@/components/Dialog/tipsDialog.vue'
export default {
  name: 'homePage',
  components: {
    goldLine,
    search,
    collapseOption,
    tipsDialog
  },
  data() {
    return {
      active: 0,
      isShow: false,
      msg: '未登錄無法進行投票，是否立即登錄',
      btnMsg: '去登錄',
      tipsUrl: '',
      isBtn1: true, // 按鈕隱藏
      cancelCheckRadio: false,
      toTopShow: false,
      srcoll: 0
    }
  },
  watch: {
    srcoll() {
      if (this.srcoll > 350) {
        this.toTopShow = true
      } else {
        this.toTopShow = false
      }
    }
  },
  computed: {
    // ...mapState({ categoryList: 'categoryList' }),
    ...mapGetters({ categoryList: 'disposalData' })
  },
  created() {
    this.getData()
    this.wxtips()
    // 提示活動起止時間
    timeConfig().then(res => {
      if (res.data.status === 0) {
        this.msg = `投票活動未開始<br>開始時間${res.data.startTime}`
        this.btnMsg = '確認'
        this.isBtn1 = false
      }
      if (res.data.status === 2) {
        // this.$router.push({ path: '/voteEnd' })
        this.msg = `投票活動于${res.data.endTime} 结束`
        this.btnMsg = '確認'
        this.isBtn1 = false
      }
    })
  },
  mounted() {
    window.addEventListener('scroll', this.srcollShow)
  },
  methods: {
    handle() {
      const oneI = localStorage.getItem('oneI')
      const twoE = localStorage.getItem('twoE')
      this.$refs.collapse.selected(oneI, twoE, 1)
    },
    wxtips() {
      // 提示关注
      if (this.$isToken() && getisweixing()) {
        wxTips(localStorage.getItem('wxid')).then((res) => {
          if (res.data) {
            this.msg = '請關注Ifood公眾號 感謝！'
            this.btnMsg = '去關注'
            this.tipsUrl = res.data
            this.isShow = true
          }
        })
      }
    },

    // 投票過期驗證
    // isVote() {
    //   this.msg = '投票暫未開始'
    //   this.btnMsg = '確認'
    //   this.isBtn1 = false
    //   this.isShow = true
    // },

    // 未登錄無法進行投票，是否立即登錄
    goLoginTips() {
      this.msg = '未登錄無法進行投票，是否立即登錄'
      this.btnMsg = '去登錄'
      this.isBtn1 = true
      this.isShow = true
    },
    // 控制弹窗
    isSelect() {
      this.isShow = true
    },
    show() {
      this.isShow = false
      this.cancelCheckRadio = true
    },
    submit() {
      this.$refs.collapse.huoqu()
    },
    goSearch() {
      if (this.$route.path !== '/search') {
        this.$router.push('/search')
      }
    },
    // 子组件更新
    updateHome() {
      this.getData()
    },
    // 獲取數據源
    getData() {
      const getDataToats = Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      if (this.$isToken()) {
        reqCategoryToken()
          .then((res) => {
            if (res.code === 401) {
              reqCategory().then((res) => {
                getDataToats.clear()
                this.$store.dispatch('reqCategory', res.rows)
                return res.rows
              }).then((data) => {
                this.active = data[0].categoryId
              })
            }
            //console.log('有token')
            this.pushData(res.rows)

            getDataToats.clear()
            return res.rows
          })
          .then((data) => {
            //console.log(data)
            this.active = data[0].categoryId
          })
      }

      if (!this.$isToken()) {
        reqCategory()
          .then((res) => {
            //console.log('沒有token')
            //console.log(res)
            getDataToats.clear()
            this.$store.dispatch('reqCategory', res.rows)
            return res.rows
          })
          .then((data) => {
            this.active = data[0].categoryId
          })
      }
    },

    // 添加其他選項
    pushData(data) {
      const obj = { otherName: '', restaurantName: '其他', other: 1 }
      for (const i in data) {
        if (data[i].type === 0) {
          data[i].restaurantList.push(obj)
        }
      }
      //console.log(data)
      this.$store.dispatch('reqCategory', data)
    },
    // 橫幅 廣告
    hengfuFun() {
      window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzA5NjI1ODcyMA==&mid=2653764977&idx=1&sn=899d277568cc3073e938bdcb882fcb92&chksm=8b6b42bebc1ccba8a1939e17d0780e0fa14bc93cc3ae5ea5c143ce140c7fbaa20a862aa8f845#rd'
    },
    srcollShow() {
      this.srcoll = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    },
    toTop() {
      // 过渡效果
      // this.toTopSpeed = setInterval(() => {
      //   document.documentElement.scrollTop =
      //   document.documentElement.scrollTop - 20
      //   // 通过改变数字实现动画延迟滚动
      //   if (this.srcoll < 10) {
      //     clearInterval(this.toTopSpeed)
      //   }
      // }, 1)
      window.pageYOffset = 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    }
  }
}
</script>
<style lang="less" scoped>
@import 'home.less';
.container{
}
.submit-divbox{
  width: 100%;
  display: flex;
  justify-content: center;
}
.hengfu-box{
  text-align: center;
  font-size: 20px;
}
.totop {
  width: 45px;
  height: 45px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #000;
  position: fixed;
  bottom: 75px;
  right: 10px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border: 2px solid #98754d;
  .top-icon{
    font-size: 18px;
    margin-top: 5px;
  }
  .top-span{
    display: block;
    margin-top: -5px;
  }
}
</style>
