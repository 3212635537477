<template>
  <div>
    <ul>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'omitList'
}
</script>

<style lang="less" scoped>
@import './omitList';
</style>
