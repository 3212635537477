export const isToken = function () {
  if (localStorage.getItem('token')) {
    return true
  } else {
    return false
  }
}

// 判断微信环境
export const getisweixing = function () {
  const wx = window.navigator.userAgent.toLowerCase()
  if (wx.match(/MicroMessenger/i) == 'micromessenger') {
    //console.log('是微信浏览器默认走微信')
    return true // 是微信端
  } else {
    //console.log('不是微信浏览器绘制原生。')
    return false
  }
}
