import requests from './request'

// 获取验证码
export const reqSendCode = (params) => requests({
  url: 'ifoodUser/sendCode',
  method: 'POST',
  data: params
})

// 獲取token
export const reqVerifCode = (data) => requests({
  url: 'ifoodUser/verificationCode',
  method: 'POST',
  data: data
})

// 分頁參數
const params = {
  isAsc: 'DESC',
  orderByColumn: 'sort',
  pageNum: 1,
  pageSize: 500
}

//  首頁數據（不帶token）
export const reqCategory = () => requests({
  url: 'category/restaurantCategory',
  method: 'GET',
  params: params
})

// 首頁數據（帶token）
export const reqCategoryToken = () => requests({
  url: 'category/token/restaurantCategory',
  method: 'GET',
  params: params
})

// 搜索页不带token
export const reqRestaurant = (value) => requests({
  url: 'ifoodRestaurant/search',
  method: 'GET',
  params: value
})

// 搜索页带token
export const reqRestaurantToken = (value) => requests({
  url: 'ifoodRestaurant/token/search',
  method: 'GET',
  params: value
})

// 提交投票
export const submittedRestaurant = (answer) => requests({
  url: 'submittedRestaurant/multiple',
  method: 'POST',
  data: answer
})

// 搜索待审核的餐厅
export const checkRestaurant = (restaurant) => requests({
  url: 'submittedRestaurant/list',
  method: 'GET',
  params: {
    restaurantName: restaurant
  }
})

// 申请审批
export const approval = (value) => requests({
  url: 'submittedRestaurant',
  method: 'POST',
  data: {
    restaurantName: value
  }
})

// 流量統計
export const ifoodCount = (data) => requests({
  url: 'ifoodCount',
  method: 'POST',
  data: data
})

// wx分享自定義内容
export const wxShareInfo = () => requests({
  url: 'wxConfig/shareInfo',
  method: 'GET'
})

// wx提示关注
export const wxTips = (id) => requests({
  url: `ifoodAttention/tip/${id}`,
  method: 'GET'
})

// 投票起止时间
export const timeConfig = () => requests({
  url: 'timeConfig/time',
  method: 'GET'
})
