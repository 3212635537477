<template>
  <div class="copyright-main">
    <div class="ifood-logo">
      <van-image
        class="vanImg1"
        fit="contain"
        :src="require('@/assets/img/ifood.png')"
      />
    </div>
    <p>© 2004–2023 ifoodmacau All rights reserved</p>
    <van-row type="flex" justify="center" class="row1">
      <van-col span="6" class="pic">
        <van-image
          style="height: 30px;width: 36px;"
          @click="openFB"
          :src="require('@/assets/img/Facebook-logo.png')"
        />
      </van-col>
      <van-col span="6" class="pic">
        <van-image
          style="height: 36px;width: 36px;"
          @click="openIN"
          :src="require('@/assets/img/Instagram-logo.png')"
        />
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: 'copyrightNotice',
  methods: {
    openFB() {
      window.open('https://www.facebook.com/ifoodmacau', '_blank')
    },
    openIN() {
      window.open('https://instagram.com/ifoodmac?igshid=YmMyMTA2M2Y', '_blank')
    }
  }
}
</script>

<style scoped lang="less">
@import 'copyright';
</style>
