<template>
  <div @click="goSearch">
    <van-search
      v-model="value"
      v-emoji
      shape="round"
      background="#181818"
      placeholder="搜尋餐廳"
      :right-icon="$route.path == '/home' ? 'search' : 'false'"
      left-icon="false"
      @search="onSearch"
      @input="inputChange"
    />
  </div>
</template>

<script>
export default {
  name: 'searchInput',
  data() {
    return {
      value: ''
    }
  },
  created() {
    // // 自动获取焦点
    // this.focusEvent()
  },
  methods: {
    onSearch(e) {
      //console.log(e)
      if (e !== '') {
        this.$emit('searchValue', e)
      }
    },
    goSearch() {
      this.$emit('goSearch')
    },
    inputChange(value) {
      //console.log(value)
      // this.value = value.replace(/\s*/g, '')
      this.$emit('inputChange', value)
    }
  }
}
</script>

<style scoped lang="less">
@import 'search.less';
</style>
