import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store/index'

Toast.setDefaultOptions({ duration: 2000 }) // 修改提示顯示時間

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 8000
})

// 請求攔截器
request.interceptors.request.use(
  (config) => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = localStorage.getItem('token')
    // eslint-disable-next-line no-prototype-builtins
    if (!config.headers.hasOwnProperty('Authorization') && token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 攔截器
request.interceptors.response.use(
  (res) => {
    if (res.data.code === 401) {
      Toast.fail('登錄過期，請重新登錄')
      store.dispatch('logout').then(() => {
        localStorage.removeItem('token')
        location.href = '#/login'
      })
    }
    return res.data
  },
  (error) => {
    // 失败的回调
    if (error.message) {
      console.log(error.message)
    }
    if (error.response) {
      console.log(error.request)
    }

    // 结束掉promis链
    return Promise.reject(error)
  }
)
export default request
