import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const Store = new Vuex.Store({
  state: {
    token: '',
    wxid: '',
    phone: '',
    categoryList: [] // 分類列表
    // canteenList: [
    //   {
    //     title: '中國餐廳',
    //     val: '', // 选项
    //     id: 1,
    //     total: 0, // 投票总数
    //     type: 0, // 0未投票 1已投票
    //     ridio: [
    //       // type为1时 票数，百分比
    //       { name: 'a', title: '選項一', poll: 0, percentage: 0 },
    //       { name: 'b', title: '選項二', poll: 0, percentage: 0 },
    //       // 其他选项  必須為數組的最後一項
    //       { name: 'c', title: '其他', other: 1 }
    //     ]
    //   },
    //   {
    //     title: '泰國餐廳',
    //     val: '',
    //     id: 2,
    //     total: 0, // 投票总数
    //     type: 0, // 0未投票 1已投票
    //     ridio: [
    //       { name: 'a', title: '選項一', poll: 0, percentage: 0 },
    //       { name: 'b', title: '選項二', poll: 0, percentage: 0 },
    //       { name: 'c', title: '其他', other: 1 }
    //     ]
    //   },

    //   {
    //     title: '韓國餐廳',
    //     val: '',
    //     id: 3,
    //     total: 0, // 投票总数
    //     type: 0, // 0未投票 1已投票
    //     ridio: [
    //       { name: 'a', title: '選項一', poll: 0, percentage: 0 },
    //       { name: 'c', title: '其他', other: 1 }
    //     ]
    //   },
    //   {
    //     title: '茶餐廳',
    //     val: 'd', // 用户投票项
    //     id: 4,
    //     total: 0, // 投票总数
    //     type: 1, // 0未投票 1已投票
    //     ridio: [
    //       // type为1时 票数，百分比
    //       { name: 'a', title: '選項一', poll: 144, percentage: 88 },
    //       { name: 'b', title: '選項二', poll: 72, percentage: 66 },
    //       { name: 'c', title: '選項三', poll: 62, percentage: 59 },
    //       { name: 'd', title: '選項四', poll: 32, percentage: 55 }
    //     ]
    //   },
    //   {
    //     title: '茶餐廳1',
    //     val: 'b',
    //     id: 5,
    //     total: 0, // 投票总数
    //     type: 1, // 0未投票 1已投票
    //     ridio: [
    //       // type为1时 票数，百分比，投票项
    //       { name: 'a', title: '選項一', poll: 77, percentage: 89 },
    //       { name: 'b', title: '選項二', poll: 59, percentage: 79 },
    //       { name: 'c', title: '選項三', poll: 22, percentage: 59 }
    //     ]
    //   }
    // ]
  },
  getters: {
    activeName(state) {
      const active = []
      for (let index = 0; index < state.categoryList.length; index++) {
        active.push(state.categoryList[index].categoryId)
      }
      return active
    },

    // 整理排名数据
    disposalData(state) {
      const arrList = state.categoryList || []
      arrList.map((item, index) => {
        if (item.type === 1) {
          //console.log(index)
          const arr1 = []
          item.restaurantList.map((list, num) => {
            if (item.restaurantList.length <= 10) {
              return item.restaurantList
            } else {
              //console.log(list)
              if (list.myFavorite) {
                // //console.log(list)
                if (num <= 9) {
                  arr1.push(item.restaurantList[0])
                  arr1.push(item.restaurantList[1])
                  arr1.push(item.restaurantList[2])
                  arr1.push(item.restaurantList[3])
                  arr1.push(item.restaurantList[4])
                  arr1.push(item.restaurantList[5])
                  arr1.push(item.restaurantList[6])
                  arr1.push(item.restaurantList[7])
                  arr1.push(item.restaurantList[8])
                  arr1.push(item.restaurantList[9])
                  item.restaurantList = []
                  return item.restaurantList.push(...arr1)
                } else {
                  arr1.push(item.restaurantList[0])
                  arr1.push(item.restaurantList[1])
                  arr1.push(item.restaurantList[2])
                  arr1.push(item.restaurantList[3])
                  arr1.push(item.restaurantList[4])
                  arr1.push(item.restaurantList[5])
                  arr1.push(item.restaurantList[6])
                  arr1.push(item.restaurantList[7])
                  arr1.push(item.restaurantList[8])
                  arr1.push(item.restaurantList[9])
                  arr1.push(item.restaurantList[num])
                  //console.log(arr1)
                  item.restaurantList = []
                  return item.restaurantList.push(...arr1)
                }
              }
            }
          })
        }
      })
      return arrList
    }
  },
  mutations: {
    LOGIN(state, value) {
      state.token = value
    },
    LOGOUT(state) {
      state.token = ''
    },
    REQCATEGORY(state, value) {
      state.categoryList = value
    },
    GETWXID(state, value) {
      state.wxid = value
    },
    GETPHONE(state, value) {
      state.phone = value
    }
  },
  actions: {
    // 登錄方法
    login(context, value) {
      context.commit('LOGIN', value)
    },

    // 登出方法
    logout(context) {
      context.commit('LOGOUT')
    },

    // 獲取分類數據
    reqCategory(context, value) {
      context.commit('REQCATEGORY', value)
    }
  }
})

export default Store
