<template>
  <div id="app">
    <router-view />
    <div class="blackBg"></div>
  </div>
</template>
<script>
import { getisweixing } from '@/utils/auth'
import { ifoodCount, wxShareInfo } from '@/api/api'
import { wxShowMenu } from '@/utils/wx'
export default {
  name: 'app',
  data() {
    return {
      shareData: {}, // wx分享内容
      isShow: false
    }
  },
  created() {
    this.getWxid()
  },
  mounted() {
    const data = {
      phone: this.$store.state.phone || '',
      wxid: this.$store.state.wxid || ''
    }
    // 統計流量
    ifoodCount(data).then((res) => {
      //console.log('流量加一')
    })
    //console.log(this.$store.state.wxid)
    if (getisweixing()) {
      // 獲取分享數據
      wxShareInfo()
        .then((res) => {
          return res.data
        })
        .then((res) => {
          if (localStorage.getItem('wxid')) {
            wxShowMenu(res).then((res) => {
              //console.log('测试')
            })
          }
        })
    }
  },
  methods: {
    getWxid() {
      // 判断浏览器环境获取wxid
      if (getisweixing()) {
        //console.log('获取')
        //console.log(localStorage.getItem('wxid'))
        if (localStorage.getItem('wxid')) {
          //console.log('再次访问')
          this.$store.commit('GETWXID', localStorage.getItem('wxid'))
        } else {
          //console.log('重定向')
          window.location.href = process.env.VUE_APP_BASE_API+'/wechat/index'
        }
      }
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        if (JSON.stringify(to.query) !== '{}') {
          //console.log('有')
          const wxid = to.query.wxid
          localStorage.setItem('wxid', wxid)
          this.$store.commit('GETWXID', wxid)
          if (getisweixing() && to.query.wxid) {
            // window.location.href = 'http://ifood.inplexmacau-official.com/home'
            window.location.href = '/#/home'
          }
        } else {
          //console.log('无')
          // localStorage.removeItem('wxid')
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="less">
.blackBg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: -9999;
  background-color: #181818;
}
</style>
