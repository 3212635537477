
<template>
  <div id="container">
    <div class="bg" style="background: #fff;">
      <van-image
        style="display: none;"
        width="100vw"
        fit="contain"
        :src="require('@/assets/img/ifood-award-logo.jpg')"
      />
      <van-image
        width="100vw"
        fit="contain"
        :src="require('@/assets/img/bg_compress_20240906001.jpg')"
      />
    </div>
    <div style="background-color: #fff;">
      <div class="bg-log-box">
        <van-image
          width="100vw"
          fit="contain"
          :src="require('@/assets/img/bottom-logo_20240902.jpg')"
        />
      </div>
    </div>
    <div class="hengfu-box" @click="hengfuFun" v-if="1==2">
      <van-image
        width="100vw"
        fit="contain"
        :src="require('@/assets/img/banner_AD_compress.png')"
      />
    </div>
    <!-- <search @goSearch="goSearch"></search> -->
    <!-- <collapseOption
      :canteenList="categoryList"
      :active="active"
      :cancelCheckRadio="cancelCheckRadio"
      @updateHome="updateHome"
      @isSelect="isSelect"
      @goLoginTips="goLoginTips"
      ref="collapse"
    ></collapseOption> -->

    <van-collapse
      v-model="active"
      :border="false"
      @change="onChange"
    >
      <div
        v-for="(item, index) in categoryList"
        :key="index"
        @click="getIndex(index)"
      >
        <van-collapse-item
          :border="false"
          :name="item.categoryId"
        >
          <template #title>
            <div>
              <div class="tips">我最喜歡</div>
              <div>{{ item.name }}</div>
            </div>
          </template>
          <div v-for="(progress, index) in item.restaurantList" :key="index">
            <div class="bigbox" v-if="index < 3">
              <div
                class="scheduleCont_1_left_1"
                :class="{ ischeched: item.val == progress.restaurantId }"
              >
                <div
                  class="un_xg_1_3_1"
                  :class="{ ischeched1: item.val == progress.restaurantId }"
                  :style="`left: ${progress.percentage}%;`"
                ></div>
              </div>
              <div class="text">
                <div :class="{ ranking: index < 3 }">
                  <div class="images" v-if="index < 3">
                    <img :src="images[index].src" />
                  </div>
                </div>

                <div class="numberOfVotes">
                  <div style="margin-left: 40px">
                    {{ progress.restaurantName }}
                  </div>
                  <!-- <div>{{ progress.numberOfVotes }} 票</div> -->
                </div>
              </div>
            </div>
          </div>
          <template #right-icon>
            <van-image
              width="30px"
              height="30px"
              :src="require('@/assets/img/3.png')"
              v-show="item.val != ''"
            />
          </template>
        </van-collapse-item>
      </div>
    </van-collapse>

    <van-image
      class="vanImg"
      width="100vw"
      fit="contain"
      :src="require('@/assets/img/background.png')"
    />
    <goldLine></goldLine>
    <!-- <div class="submit-divbox">
      <div class="submitBtn" @click="submit">
      <div class="img"><img src="../../assets/img/2.png" /></div>
      <div>提交</div>
    </div>
    </div> -->
    <tipsDialog
      :show="isShow"
      :msg="msg"
      :btn1="btnMsg"
      :isBtn1="isBtn1"
      :url="tipsUrl"
      @show="show"
    ></tipsDialog>

    <div class="totop" v-show="toTopShow" @click="toTop()">
      <van-icon name="arrow-up" class="top-icon"/>
      <span class="top-span">TOP</span>
    </div>
  </div>
</template>

<script>
import { reqCategory, timeConfig } from '@/api/api'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
import goldLine from '@/components/goldLine/goldLine.vue'
// import search from '@/components/search/search.vue'
// import collapseOption from '@/components/collapse/collapse.vue'
import tipsDialog from '@/components/Dialog/tipsDialog.vue'
export default {
  name: 'voteEnd',
  components: {
    goldLine,
    // search,
    // collapseOption,
    tipsDialog
  },
  data() {
    return {
      active: ['index'],
      isShow: true,
      msg: '未登錄無法進行投票，是否立即登錄',
      btnMsg: '去登錄',
      tipsUrl: '',
      isBtn1: true, // 按鈕隱藏
      cancelCheckRadio: false,
      toTopShow: false,
      srcoll: 0,
      indexTab: 0,
      unfold: 0, // 手风琴展开的面板的index 首頁初始展開第一項
      isSelect: '', // 单选按钮的选中index
      images: [
        { src: require('../../assets/img/11.png') },
        { src: require('../../assets/img/22.png') },
        { src: require('../../assets/img/33.png') }
      ]
    }
  },
  watch: {
    srcoll() {
      if (this.srcoll > 350) {
        this.toTopShow = true
      } else {
        this.toTopShow = false
      }
    }
  },
  computed: {
    // ...mapState({ categoryList: 'categoryList' }),
    ...mapGetters({ categoryList: 'disposalData' })
  },
  created() {
    this.getData()
    // 提示活動起止時間
    timeConfig().then(res => {
      if (res.data.status === 0) {
        this.msg = `投票活動未開始<br>開始時間${res.data.startTime}`
        this.btnMsg = '確認'
        this.isBtn1 = false
      }
      if (res.data.status === 2) {
        this.msg = '投票活動已结束'
        this.btnMsg = '確認'
        this.isBtn1 = false
      }
    })
  },
  mounted() {
    window.addEventListener('scroll', this.srcollShow)
  },
  methods: {
    getIndex(e) {
      this.indexTab = e
    },
    onChange(e) {
      // 判断面板是否展开
      if (typeof e === 'number' && !isNaN(e)) {
        this.unfold = e
        this.isSelect = ''
      } else {
        this.unfold = ''
      }
    },
    handle() {
      const oneI = localStorage.getItem('oneI')
      const twoE = localStorage.getItem('twoE')
      this.$refs.collapse.selected(oneI, twoE, 1)
    },
    // 未登錄無法進行投票，是否立即登錄
    goLoginTips() {
      this.msg = '未登錄無法進行投票，是否立即登錄'
      this.btnMsg = '去登錄'
      this.isBtn1 = true
      this.isShow = true
    },
    show() {
      this.isShow = false
      this.cancelCheckRadio = true
    },
    submit() {
      this.$refs.collapse.huoqu()
    },
    goSearch() {
      if (this.$route.path !== '/search') {
        this.$router.push('/search')
      }
    },
    // 子组件更新
    updateHome() {
      this.getData()
    },
    // 獲取數據源
    getData() {
      const getDataToats = Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      if (!this.$isToken()) {
        reqCategory()
          .then((res) => {
            //console.log('沒有token')
            getDataToats.clear()
            this.$store.dispatch('reqCategory', res.rows)
            return res.rows
          })
          .then((data) => {
            // this.active = data[0].categoryId
            for (const act of data) {
              this.active.push(act.categoryId)
            }
          })
      }
    },

    // 添加其他選項
    pushData(data) {
      const obj = { otherName: '', restaurantName: '其他', other: 1 }
      for (const i in data) {
        if (data[i].type === 0) {
          data[i].restaurantList.push(obj)
        }
      }
      //console.log(data)
      this.$store.dispatch('reqCategory', data)
    },
    // 橫幅 廣告
    hengfuFun() {
      window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzA5NjI1ODcyMA==&mid=2653764977&idx=1&sn=899d277568cc3073e938bdcb882fcb92&chksm=8b6b42bebc1ccba8a1939e17d0780e0fa14bc93cc3ae5ea5c143ce140c7fbaa20a862aa8f845#rd'
    },
    srcollShow() {
      this.srcoll = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    },
    toTop() {
      window.pageYOffset = 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    }
  }
}
</script>
<style lang="less" scoped>
@import 'home.less';
@import '@/components/collapse/collapse.less';
.submit-divbox{
  width: 100%;
  display: flex;
  justify-content: center;
}
.hengfu-box{
  text-align: center;
  font-size: 20px;
}
.totop {
  width: 45px;
  height: 45px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #000;
  position: fixed;
  bottom: 75px;
  right: 10px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border: 2px solid #98754d;
  .top-icon{
    font-size: 18px;
    margin-top: 5px;
  }
  .top-span{
    display: block;
    margin-top: -5px;
  }
}
</style>
