<template>
  <div class="main">
    <div>
      <div class="bg" style="background: #fff;">
        <van-image
          width="100vw"
          fit="contain"
          :src="require('@/assets/img/bg_compress_20240906001.jpg')"
        />
      </div>
    </div>
    <div style="background-color: #fff;">
      <div class="bg-log-box">
        <van-image
          width="100vw"
          fit="contain"
          :src="require('@/assets/img/bottom-logo_20240902.jpg')"
        />
      </div>
    </div>
    <div class="hengfu-box" @click="hengfuFun" v-if="1==2">
      <van-image
        width="100vw"
        fit="contain"
        :src="require('@/assets/img/banner_AD_compress.png')"
      />
    </div>
    <p class="loginTitle">投票登記</p>
    <SMSLog></SMSLog>
    <van-image
      class="vanImg"
      width="100vw"
      fit="contain"
      :src="require('@/assets/img/background.png')"
    />
    <goldLine></goldLine>
    <copyright-notice></copyright-notice>
  </div>
</template>

<script>
import SMSLog from '@/components/SMSLog/SMSLog'
import goldLine from '@/components/goldLine/goldLine'
import CopyrightNotice from '@/components/copyright/copyright'
export default {
  name: 'LoginPage',
  components: {
    CopyrightNotice,
    SMSLog,
    goldLine
  },
  methods: {
    // 橫幅 廣告
    hengfuFun() {
      window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzA5NjI1ODcyMA==&mid=2653764977&idx=1&sn=899d277568cc3073e938bdcb882fcb92&chksm=8b6b42bebc1ccba8a1939e17d0780e0fa14bc93cc3ae5ea5c143ce140c7fbaa20a862aa8f845#rd'
    }
  }
}
</script>

<style scoped lang="less">
@import 'login.less';

.hengfu-box{
  text-align: center;
  font-size: 20px;
}
.loginTitle{
  padding-top: 30px;
}
</style>
