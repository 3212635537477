<template>
  <div class="line"></div>
</template>

<script>
export default {
  name: 'goldLine'
}
</script>

<style scoped lang="less">
  @import "goldLine";
</style>
