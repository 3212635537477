<template>
  <div>
    <van-popup v-model="show" :round="true" :close-on-click-overlay="false"
      ><div class="LoginCard">
        <div class="conatiner">
          <div class="title" v-if="!jingminlianFeedback">提示</div>
          <!-- <van-image
            class="ela_logo"
            v-if="jingminlianFeedback"
            width="40vw"
            fit="contain"
            :src="require('@/assets/img/ela_logo.png')"
          /> -->
          <div class="msg" v-html="msg"></div>

          <div class="btn" :style="{width:jingminlianFeedback?'50%':'100%'}">
            <div class="btn1 btn2" v-if="isBtn1" @click="cancel">取消</div>
            <div class="btn1"  @click="confirm">{{ btn1 }}</div>
          </div>
        </div>
      </div></van-popup
    >
  </div>
</template>

<script>
export default {
  name: 'tipsDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: ''
    },
    isBtn1: {
      type: Boolean,
      default: true
    },
    btn1: {
      type: String,
      default: '去登錄'
    },
    url: {
      type: String,
      default: ''
    },
    jingminlianFeedback: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    confirm() {
      if (this.btn1 == '去登錄') {
        this.$router.push('/login')
      } else if (this.btn1 == '去關注') {
        // window.location.href = this.url
        window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzA5NjI1ODcyMA==&mid=2653764977&idx=1&sn=899d277568cc3073e938bdcb882fcb92&chksm=8b6b42bebc1ccba8a1939e17d0780e0fa14bc93cc3ae5ea5c143ce140c7fbaa20a862aa8f845#rd'
      } else {
        this.$emit('show')
      }
    },
    // 取消
    cancel() {
      this.$parent.handle()//调用子组件2的方法
      this.$emit('show')
    }
  }
}
</script>

<style lang="less" scoped>
@import 'tipsDialog.less';

.ela_logo{
  margin-top: 20px;
}
</style>
