import axios from 'axios'
import wx from 'weixin-js-sdk'

// url
const baseUrl = location.href.split('#')[0]
const wxShowMenu = (shareData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_BASE_API+'/wechat/shareSdk', {
        wxid: localStorage.getItem('wxid'),
        url: `${baseUrl}`
      })
      .then(
        (res) => {
          //console.log(res.data.data)
          //console.log(shareData)
          const { appId, nonceStr, signature, timestamp, url } = res.data.data

          wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
          })
          //console.log('路径' + baseUrl)
          wx.ready(() => {
            // 分享到朋友
            wx.updateAppMessageShareData({
              title: shareData.shareTitle, // 分享标题
              desc: shareData.shareDescribe,
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: shareData.sharePic, // 分享图标
              success: function () {
                // 设置成功
                //console.log('分享成功111')
              }
            })
            // 分享到朋友圈
            wx.updateTimelineShareData({
              title: shareData.shareTitle,
              desc: shareData.shareDescribe,
              link: url,
              imgUrl: shareData.sharePic,
              success: function () {
                //console.log('分享成功')
              },
              cancel: function () {
                //console.log('取消分享')
              }
            })
          })
          resolve(res)
        },
        (err) => {
          reject(err)
        }
      )
  })
}
export { wxShowMenu }
