<template>
  <div>
    <van-collapse
      v-model="activeName"
      :accordion="$route.path == '/home' ? true : false"
      :border="false"
      @change="onChange"
    >
      <div
        v-for="(item, index) in canteenList"
        :key="index"
        @click="getIndex(index)"
      >
        <van-collapse-item
          v-if="item.type === 0"
          :border="false"
          :name="item.categoryId"
        >
          <template #title>
            <div>
              <div class="tips">我最喜歡</div>
              <div>{{ item.name }}</div>
            </div>
          </template>
          <van-radio-group
            v-model="item.val"
            v-for="(val, num) in item.restaurantList"
            :key="num"
            @change="changeFn"
          >
            <div @click="selected(index,num,val.other)">
              <van-radio
                shape="square"
                :name="val.restaurantId"
                :class="item.val === val.restaurantId ? 'checked' : ''"
              >
                <!-- 其他輸入框 -->
                <van-field
                  v-model="valOtherName[index]"
                  v-emoji
                  ref="field"
                  @input="getInput"
                  @focus="getFocus"
                  placeholder="請輸入餐廳名稱"
                  v-if="
                    unfold == item.categoryId && isSelect == num && val.other
                  "
                />
                <span v-else>{{ val.restaurantName }}</span>
              </van-radio>
            </div>
          </van-radio-group>
          <template #right-icon v-if="$route.path == '/home'">
            <!-- 选中选项后展示 -->
            <van-image
              width="30px"
              height="30px"
              :src="require('@/assets/img/1.png')"
              v-show="item.val"
            />
            <van-icon
              v-show="!item.val"
              name="arrow"
              :class="{
                go: unfold === item.categoryId,
                to: unfold !== item.categoryId
              }"
            />
          </template>
        </van-collapse-item>
        <van-collapse-item
          v-if="item.type === 1"
          :border="false"
          :name="item.categoryId"
        >
          <template #title>
            <div>
              <div class="tips">我最喜歡</div>
              <div>{{ item.name }}</div>
            </div>
          </template>
          <div v-for="(progress, index) in item.restaurantList" :key="index">
            <div class="bigbox">
              <div
                class="scheduleCont_1_left_1"
                :class="{ ischeched: item.val == progress.restaurantId }"
              >
                <div
                  class="un_xg_1_3_1"
                  :class="{ ischeched1: item.val == progress.restaurantId }"
                  :style="`left: ${progress.percentage}%;`"
                ></div>
              </div>
              <div class="text">
                <div :class="{ ranking: index < 3 }">
                  <div class="images" v-if="index < 3">
                    <img :src="images[index].src" />
                  </div>
                </div>

                <div class="numberOfVotes">
                  <div style="margin-left: 40px">
                    {{ progress.restaurantName }}
                  </div>
                  <div>{{ progress.numberOfVotes }} 票</div>
                </div>
              </div>
            </div>
            <omit
              v-if="index >= 9 && index != 10 && item.restaurantList.length != 10"
            ></omit>
          </div>
          <template #right-icon>
            <van-image
              width="30px"
              height="30px"
              :src="require('@/assets/img/3.png')"
              v-show="item.val != ''"
            />
          </template>
        </van-collapse-item>
      </div>
    </van-collapse>

    <tipsDialog
      :jingminlianFeedback="jingminlianFeedback"
      :show="isShow"
      :msg="tipsMsg"
      :btn1="btnMsg"
      :isBtn1="isBtn1"
      @show="show"
    ></tipsDialog>
  </div>
</template>

<script>
import omit from '@/components/omit/omitList.vue'
import { submittedRestaurant } from '@/api/api'
import { Toast } from 'vant'
import { set } from 'vue'
import tipsDialog from '@/components/Dialog/tipsDialog.vue'
export default {
  name: 'collapseOption',
  props: ['canteenList', 'active', 'cancelCheckRadio'],
  components: {
    omit,
    tipsDialog
  },
  data() {
    return {
      activeName: '', // 手风琴默认展开的index
      radio: '',
      unfold: 0, // 手风琴展开的面板的index 首頁初始展開第一項
      isSelect: '', // 单选按钮的选中index
      indexTab: 0, // 餐廳分類下標
      answer: {
        category: []
      }, // 提交选项
      images: [
        { src: require('../../assets/img/11.png') },
        { src: require('../../assets/img/22.png') },
        { src: require('../../assets/img/33.png') }
      ],
      valOtherName: [],
      checked: '',
      checkRadioFlag: false,
      restaurantIdsed: [],
      checkCT: false,
      isShow: false,
      tipsMsg: '感謝您的參與~',
      btnMsg: '關閉',
      isBtn1: false, // 按鈕隱藏
      jingminlianFeedback: false
    }
  },
  watch: {
    active(newValue, old) {
      this.unfold = this.activeName = newValue
    }
  },
  created() {
    // this.activeName = this.active
    // this.unfold = this.activeName
    // localStorage.removeItem('oneI')
    // localStorage.removeItem('twoE')
  },
  mounted() {
    // console.log(this.cancelCheckRadio)
    // this.activeName = this.active
    // //console.log(this.activeName)
  },
  methods: {
    getIndex(e) {
      this.indexTab = e
    },
    onChange(e) {
      //console.log('unfold的值為' + e)
      // 判断面板是否展开
      if (typeof e === 'number' && !isNaN(e)) {
        this.unfold = e
        this.isSelect = ''
      } else {
        this.unfold = ''
      }
    },
    getInput(e) {
      // console.log(e)
      // console.log(this.unfold)
      // console.log(this.valOtherName[this.indexTab])
      // this.canteenList[this.indexTab].val = e
      // this.canteenList[this.indexTab].restaurantList[this.isSelect].restaurantId = e
    },
    // 獲取焦點后清空name的值
    getFocus() {
      //console.log(
      //   this.canteenList[this.indexTab].restaurantList[this.isSelect].otherName
      // )
      // this.canteenList[this.indexTab].restaurantList[this.isSelect].otherName =
      //   ''
    },
    // 判断是否选中
    selected(i, e, other) {
      localStorage.setItem('oneI', i)
      localStorage.setItem('twoE', e)
      // console.log(this.cancelCheckRadio)
      // 搜索頁沒有其他選項
      // console.log(e) // 0,1,2
      // const restaurantIded = this.canteenList[i].restaurantList[e].restaurantId
      // console.log(other)
      // console.log(this.canteenList[i].restaurantList[e].other)
      if (!this.checkRadioFlag) {
        this.canteenList[i].val = ''
        this.checkCT = false
        this.unfold = ''
        this.valOtherName[i] = ''
      } else {
        this.checkRadioFlag = false
        this.checkCT = true
        this.unfold = this.canteenList[i].categoryId
      }
      if (this.$route.path !== '/search') {
        this.isSelect = e
        //console.log('isSelect的值為' + this.isSelect)
        if (
          this.canteenList[this.indexTab].restaurantList.length - 1 ==
          this.isSelect
        ) {
          this.getInputFocus()
        }
      }

      // 搜索頁判斷選中
      if (this.$route.path === '/search') {
        this.$emit('select')
      }

      // 判断是否选中（未登录状态）
      if (!this.$isToken()) {
        this.$emit('isSelect')
      }
    },
    changeFn() {
      this.checkRadioFlag = true
    },
    // 让選中的輸入框自動獲取焦點  （方法廢棄）
    getInputFocus() {
      this.$nextTick(() => {
        const input = document.getElementsByTagName('input')[1]
        //console.log(input)
        if (this.checkCT) {
          input.focus()
        }
        // 解決單選按鈕需要再次被選中的問題
        // this.canteenList[this.indexTab].val = ''
      })
    },

    // 提交待更正
    huoqu() {
      if (this.$isToken()) {
        this.getAnswer()
        //console.log(this.answer.category.length)

        if (this.answer.category.length !== 0) {
          submittedRestaurant(this.answer).then((res) => {
            //console.log(res)
            if (res.code === 200) {
              this.$emit('updateHome')
              this.$emit('updateSearch')
              // setTimeout(() => {
              //   Toast.success('投票成功')
              // }, 400)
              setTimeout(() => {
                this.isShow = true
                this.jingminlianFeedback = true
              }, 600)
            } else {
              Toast.fail(res.msg)
            }
            return res
          })
        } else {
          Toast.fail('請選擇餐廳')
        }
      } else {
        this.$emit('goLoginTips')
      }
      this.valOtherName = []
    },
    show() {
      this.isShow = false
    },

    // 獲取答案
    getAnswer() {
      // console.log(this.valOtherName)
      const category = []
      for (let i in this.canteenList) {
        const flag = this.canteenList[i].restaurantList.every(
          (item) => item.myFavorite == null
        )
        //console.log(flag)
        if (flag) {
          const asc = this.canteenList[i].categoryId
          //console.log(asc)
          if (typeof this.canteenList[i].val == 'number') {
            category.push({
              categoryId: asc,
              restaurantList: [{ restaurantId: this.canteenList[i].val }]
            })
          } else if (this.valOtherName[i]) {
            // console.log(this.valOtherName[i])
            category.push({
              categoryId: asc,
              restaurantList: [{ otherName: this.valOtherName[i] }]
            })
          }
        }
      }
      // 过滤
      this.answer.category = category.filter(
        (item) => item.restaurantList[0].restaurantId !== null
      )
      //console.log(category)

      // console.log(this.answer.category)
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/components/collapse/collapse.less';
</style>
