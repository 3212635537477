import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import { isToken } from '@/utils/auth'
import 'vant/lib/index.css'
import '@/assets/css/global.css'
import emoji from '@/utils/emoji'

Vue.use(Vant)
Vue.directive('emoji', emoji)
Vue.config.productionTip = false
Vue.prototype.$isToken = isToken
new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  render: (h) => h(App)
}).$mount('#app')
