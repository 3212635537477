import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import Home from '@/views/home/home'
import Login from '@/views/login/Login'
import Search from '@/views/search/searcPage'
import voteEnd from '@/views/home/voteEnd'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/voteEnd',
    name: '活動已結束',
    component: voteEnd
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes,
  // 路由滚动行为
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.path === '/search') {
    // 清空仓库
    store.dispatch('reqCategory', [])
    next()
  }
  next()
})

export default router
