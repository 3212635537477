<template>
  <div>
    <div class="outer">
      <div class="header">
        <van-icon
          name="arrow-left"
          size="30"
          color="#B39370"
          @click="getBack"
        />
        <search
          style="width: 310px"
          @searchValue="searchValue($event)"
          @inputChange="inputChange($event)"
        ></search>
      </div>
      <goldLine></goldLine>
      <p class="tips" v-if="total <= 1">{{ total }} 個可進行投票的結果</p>
      <p class="tips" v-else>{{ total }} 個可以投票的類似結果</p>
      <collapseSearch
        ref="collapse"
        :canteenList="categoryList"
        :active="active"
        @select="select"
        @isSelect="isSelect"
        @goLoginTips="goLoginTips"
        @updateSearch="updateSearch(tipsTitle)"
      ></collapseSearch>
      <p v-show="exhibition && !isRepeat" class="tips">還未找到理想的效果？</p>
      <tipsPopup
        v-show="exhibition"
        :title="tipsTitle"
        :auditStatus="auditStatus"
        :isRepeat="isRepeat"
        @updateSearch="updateSearch($event)"
        @searchTipslogin="searchTipslogin"
      ></tipsPopup>
    </div>
    <tipsDialog
      :show="dialogShow"
      :msg="msg"
      :btn1="btnMsg"
      :isBtn1="isBtn1"
      @show="show"
    ></tipsDialog>
    <div class="bottom" v-show="isShow">
      <goldLine></goldLine>
      <div class="submitBtn-box">
        <div class="submitBtn" @click="submit">
          <div class="img"><img src="../../assets/img/2.png" /></div>
          <div>提交</div>
        </div>
      </div>
    </div>
    <div class="totop" v-show="toTopShow" @click="toTop()">
      <van-icon name="arrow-up" class="top-icon"/>
      <span class="top-span">TOP</span>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { mapState, mapGetters } from 'vuex'
import goldLine from '@/components/goldLine/goldLine.vue'
import search from '@/components/search/search.vue'
import collapseSearch from '@/components/collapse/collapse.vue'
import tipsPopup from '@/components/tips/tips.vue'
import tipsDialog from '@/components/Dialog/tipsDialog.vue'
import { reqRestaurant, reqRestaurantToken, checkRestaurant, timeConfig } from '@/api/api'
export default {
  name: 'searcPage',
  components: { search, goldLine, collapseSearch, tipsPopup, tipsDialog },
  data() {
    return {
      isShow: false,
      total: 0, // 可投票数
      tipsTitle: '',
      btnMsg: '去登錄',
      dialogShow: false,
      exhibition: false, // 是否展示tips
      auditStatus: false, // 是否在审核中
      isRepeat: false, // 搜索時和已存在的餐廳 則不能重複申請審批
      isBtn1: true, // 按鈕隱藏
      msg: '未登錄無法進行投票，是否立即登錄',
      toTopShow: false,
      srcoll: 0
    }
  },
  computed: {
    ...mapState({ categoryList: 'categoryList' }),
    ...mapGetters({ active: 'activeName' })
  },
  created() {
    this.focusEvent()

    timeConfig().then(res => {
      if (res.data.status === 0) {
        this.msg = `投票活動未開始開始時間${res.data.startTime}`
        this.btnMsg = '確認'
        this.isBtn1 = false
      }
      if (res.data.status === 2) {
        this.msg = `投票活動于${res.data.endTime} 结束`
        this.btnMsg = '確認'
        this.isBtn1 = false
      }
    })
    // this.getData()
  },
  watch: {
    srcoll() {
      if (this.srcoll > 350) {
        this.toTopShow = true
      } else {
        this.toTopShow = false
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.srcollShow)
  },
  methods: {
    handle() {
      const oneI = localStorage.getItem('oneI')
      const twoE = localStorage.getItem('twoE')
      this.$refs.collapse.selected(oneI, twoE, 1)
    },
    // 申請審批 提示登陸
    searchTipslogin() {
      this.msg = '未登錄無法進行投票，是否立即登錄'
      this.btnMsg = '去登錄'
      this.isBtn1 = true
      this.dialogShow = true
    },

    // 未登錄無法進行投票，是否立即登錄
    goLoginTips() {
      this.msg = '未登錄無法進行投票，是否立即登錄'
      this.btnMsg = '去登錄'
      this.isBtn1 = true
      this.dialogShow = true
    },
    // 控制弹窗
    show() {
      this.dialogShow = false
    },
    // 控制弹窗
    isSelect() {
      this.dialogShow = true
    },

    submit() {
      this.$refs.collapse.huoqu()
    },
    getBack() {
      this.$router.back()
    },
    focusEvent() {
      this.$nextTick(() => {
        try {
          const input = document.getElementsByClassName('van-field__control')[0]
          input.focus()
        } catch (e) {}
      })
    },
    select() {
      this.isShow = true
    },
    // 輸入觸發
    inputChange(value) {
      // const val = value.replace(/\s*/g, '')
      const reg = /^\s/
      // const val2=value.replace(/(^\s*)|(\s*$)/g, '')
      // const valLenght = value.indexOf(' ')
      if (value.substr(0, 1) === '' || value.substr(0, 1) === null || reg.test(value)) {
        Toast({ message: '搜索內容的開頭不能以空格開始~', position: 'top' })
      } else {
        this.searchValue(value)
      }
      // if (value === '' || value === undefined || value === null || valLenght !== -1) {
      // if (value === '' || value === undefined || value === null) {
      //   Toast({ message: '不可輸入空格~', position: 'top' })
      //   this.exhibition = false
      // } else {
      // this.searchValue(value)
      // }
    },
    // 触发搜索
    searchValue(e) {
      this.isRepeat = false
      // console.log('父组件' + e)
      this.tipsTitle = e
      this.getData(e)
      this.exhibition = true
      this.isShow = false
    },

    getData(e) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })

      const params = {
        restaurantName: e,
        isAsc: 'DESC',
        orderByColumn: 'sort',
        pageNum: 1,
        pageSize: 50000
      }
      if (this.$isToken()) {
        reqRestaurantToken(params).then((res) => {
          // //console.log('有token')
          // //console.log(res.rows)
          this.$store.dispatch('reqCategory', res.rows)
          this.total = res.total

          res.rows.forEach(item => { // 判斷搜索的餐廳是否和原有的餐廳名字一致
            item.restaurantList.forEach(item2 => {
              if (item2.restaurantName === this.tipsTitle) {
                this.isRepeat = true
              }
            })
          })

          Toast.clear()
          return res.rows
        })
        this.check(e)
      }

      if (!this.$isToken()) {
        reqRestaurant(params).then((res) => {
          // //console.log('沒有token')
          // console.log(res)
          this.total = res.total
          this.$store.dispatch('reqCategory', res.rows)
          Toast.clear()

          res.rows.forEach(item => { // 判斷搜索的餐廳是否和原有的餐廳名字一致
            item.restaurantList.forEach(item2 => {
              if (item2.restaurantName === this.tipsTitle) {
                this.isRepeat = true
              }
            })
          })
        })
        this.check(e)
      }
    },

    // 查询待审核
    check(restaurantName) {
      checkRestaurant(restaurantName).then((result) => {
        //console.log(result.total)
        if (result.total > 0 && !result.rows[0].auditStatus) {
          // //console.log(result.rows[0].auditStatus)
          this.auditStatus = true
        } else {
          this.auditStatus = false
        }
      })
    },
    // 数据刷新
    updateSearch(value) {
      this.searchValue(value)
    },
    srcollShow() {
      this.srcoll = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    },
    toTop() {
      // 过渡效果
      // this.toTopSpeed = setInterval(() => {
      //   document.documentElement.scrollTop =
      //   document.documentElement.scrollTop - 20
      //   // 通过改变数字实现动画延迟滚动
      //   if (this.srcoll < 10) {
      //     clearInterval(this.toTopSpeed)
      //   }
      // }, 1)
      window.pageYOffset = 0
      document.documentElement.scrollTop = 0 // 微信瀏覽器 始終為 0 失效
      document.body.scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
@import "searcPage.less";
.totop {
  width: 45px;
  height: 45px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #000;
  position: fixed;
  bottom: 75px;
  right: 10px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border: 2px solid #98754d;
  .top-icon{
    font-size: 18px;
    margin-top: 5px;
  }
  .top-span{
    display: block;
    margin-top: -5px;
  }
}
</style>
