<template>
  <div class="LoginCard">
    <div class="conatiner">
      <div class="title" v-if="!auditStatus && !isRepeat">{{ title }}</div>
      <div class="msg" v-if="!auditStatus && !isRepeat">是否幫這間餐廳申請為 「我最愛餐廳」 </div>
      <div class="msg msg1" v-if="auditStatus">這間餐廳的申請正在審批中，請耐心等待...</div>
      <div class="btn1" v-show="!auditStatus && !isRepeat" @click="submit(title)">
        申請審批
      </div>
      <div class="btn1 btn2" @click="goHome">返回投票頁</div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { approval } from '@/api/api'
export default {
  name: 'tipsPopup',
  props: ['title', 'auditStatus', 'isRepeat'],
  methods: {
    goHome() {
      this.$router.replace('/home')
    },
    submit(value) {
      if (this.$isToken()) {
        approval(value).then((res) => {
          //console.log(res)
          if (res.code === 200) {
            Toast.success('已提交申請')
            // 数据刷新
            this.$emit('updateSearch', value)
          } else {
            Toast.fail(res.msg)
          }
        })
      } else {
        // const content = {
        //   msg: '未登錄無法進行投票，是否立即登錄',
        //   btnMsg: '去登錄'
        // }
        this.$emit('searchTipslogin')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import 'tips.less';
</style>
